<template>
  <div>
    <!-- 顶部图片 -->
    <div class="banner">
      <img
        class="banner-img"
        src="../../../assets/images/banner/mendian.png"
        alt=""
      />
    </div>

    <!-- 门店地址 -->
    <div class="storemap">
      <div class="dizhi">
        <p class="title">门店地址</p>
        <!-- <p class="line"></p> -->
        <!-- <img src="../../../assets/images/map/shun.png" alt="" /> -->
      </div>
      <div class="yezaimap1" v-for="(item, index) in shop" :key="item.id">
        <!-- <p class="num">{{ item.id >= 10 ? item.id : "0" + item.id }}</p> -->
        <p class="num">{{ index + 1 >= 10 ? index + 1 : "0" + (index + 1) }}</p>
        <div class="content">
          <div class="left">
            <img :src="item.shopImg" alt="" />
          </div>
          <div class="centre">
            <p>{{ item.shopName }}</p>
            <p><span>服务时间:</span>{{ item.workTime }}</p>
            <p><span>门店地址: </span>{{ item.shopAddr }}</p>
            <p class="dizhi" v-if="item.busRoute"><span>公交路线:</span>{{ item.busRoute }}</p>
            <p class="dizhi" v-if="item.rideGuidance"><span> 乘车指引:</span>{{ item.rideGuidance }}</p>
          </div>
          <div class="right">
            <!-- <bmap
              ak="cmmtZ69mjRmZz2Xp9P5ND2GhjO7bBxvO"
              class="map"
              :center="item.center"
              :zoom="zoom"
              :scroll-wheel-zoom="true"
              @ready="handler"
            ></bmap> -->
            <baidu-map
              class="map"
              :center="item.center"
              :zoom="zoom"
            ></baidu-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import bmap from "vue-baidu-map/components/map/Map.vue";
import { shopList } from "@/api/my/my.js";
import { ossL } from "@/api/common/oss/ossL.js";
export default {
  data() {
    return {
      // center: { lng: 113.277811, lat: 22.824019 }, //113.277811,22.824019
      // centerL: { lng: 113.109905, lat: 23.016995 }, //113.109905,23.016995
      zoom: 17,
      shop: [],
    };
  },
  created() {
    this.$store.state.directIndex = 2;
    this.shopList();
  },
  mounted() {},
  methods: {
    async shopList() {
      const { code, data } = await shopList();
      if (code == 0) {
        // console.log('44',data);
        data.map(async (item) => {
          this.shop.push(
            Object.assign({}, item, {
              center: {
                lng: item.lon,
                lat: item.lat,
              },
              shopImg: ossL(item.shopImg),
              // handler: this.handlerLL( item.lon, item.lat),
            })
          );
        });
      }
    },
    // handler({ BMap, map }) {
    //   var point = new BMap.Point(113.277811, 22.824019);
    //   map.centerAndZoom(point, 19);
    //   var marker = new BMap.Marker(point);
    //   map.addOverlay(marker);
    //   var circle = new BMap.Circle(point, 1, {
    //     strokeColor: "Red",
    //     strokeWeight: 1,
    //     strokeOpacity: 1,
    //     Color: "Red",
    //     fillColor: "#f03",
    //   });
    //   map.addOverlay(circle);
    // },
    // handlerL({ BMap, map }) {
    //   var point = new BMap.Point(113.109905, 23.016995);
    //   map.centerAndZoom(point, 19);
    //   var marker = new BMap.Marker(point);
    //   map.addOverlay(marker);
    //   var circle = new BMap.Circle(point, 1, {
    //     strokeColor: "Red",
    //     strokeWeight: 1,
    //     strokeOpacity: 1,
    //     Color: "Red",
    //     fillColor: "#f03",
    //   });
    //   map.addOverlay(circle);
    // },
    handler({ BMap, map }, lon = 113.109905, lat = 23.016995) {
      var point = new BMap.Point(lon, lat);
      map.centerAndZoom(point, 19);
      var marker = new BMap.Marker(point);
      map.addOverlay(marker);
      var circle = new BMap.Circle(point, 1, {
        strokeColor: "Red",
        strokeWeight: 1,
        strokeOpacity: 1,
        Color: "Red",
        fillColor: "#f03",
      });
      map.addOverlay(circle);
    },
  },
  components: {
    // bmap,
  },
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-position: center center;
  .banner-img {
    width: 100%;
    height: 100%;
    @media screen {
      min-width: 1920px;
    }
  }
}

.storemap {
  width: 100%;
  // height: 1080px;
  background: #f7f7f7;
  margin: 0 auto;
  padding-bottom: 100px;
  // @media screen {
  //   min-width: 1920px;
  // }
  .dizhi {
    .title {
      font-size: 34px;
      font-weight: blod;
      color: #555555;
      line-height: 48px;
      text-align: center;
      // margin-top: 100px;
      padding-top: 100px;
    }
    .line {
      width: 80px;
      height: 4px;
      background: #fd686e;
      margin: 0 auto;
      margin-top: 20px;
    }
  }

  .yezaimap1 {
    width: 1200px;
    // height: 340px;
    // height: 340px;
    // height: 400px;
    height: 385px;
    box-sizing: border-box;
    margin: 60px auto;
    background: #fff;
    padding-top: 30px;
    .num {
      width: 84px;
      height: 42px;
      font-size: 30px;
      color: #fff;
      line-height: 42px;
      background: linear-gradient(105deg, #ffa1a7 0%, #fd686e 100%);
      border-radius: 0px 30px 30px 0px;
      text-align: center;
    }
    .content {
      width: 1200px;
      height: 180px;
      display: flex;
      flex-direction: row;
      margin-top: 38px;
      margin-left: 30px;

      .left {
        width: 240px;
        height: 180px;
        background: pink;
        img {
          width: 240px;
          height: 180px;
        }
      }
      .centre {
        width: 500px;
        height: 180px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #555555;
        line-height: 24px;
        margin-left: 20px;
        padding-right: 20px;
        text-align: justify;
        :first-child {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #555555;
          margin-bottom: 30px;
          margin-top: 8px;
        }
        :nth-child(2) > span {
          color: #fd686e;
          font-size: 14px;
          margin-top: 10px;
          padding-right: 12px;
        }
        :nth-child(3) {
          margin-bottom: 10px;
          margin-top: 15px;
          span {
            font-size: 14px;
            color: #fd686e;
            padding-right: 8px;
          }
        }
        .dizhi {
          span {
            font-size: 14px;
            color: #555555;
            padding-right: 8px;
          }
        }
      }
      .right {
        .map {
          width: 336px;
          height: 216px;
        }
      }
    }
  }
}
</style>
